import { observable, action, toJS } from 'mobx';
import * as API from '../lib/api';
import * as BlogAPI from '../lib/blogApi';

import SessionStore from './sessionStore';
import UserStore from './userStore';

import moment from 'moment';

import { constructArticleForStore } from '../factories/articleFactory';

const debug = require('debug')('OE Dashboard Store');

class store {
  /**
   * User authentication store stuff
   */
  constructor() {
    this.sessionStore = new SessionStore(this);
    this.userStore = new UserStore(this);
  }

  isFetching = observable.box(false);

  /*
  formMessage = observable.box('Enter a team above');
  teamInView = observable.box(false);
  rosterInView = observable([]);
  rosters = observable.map({});

  addRoster = action(async (teamId) => {
    debug(`Adding roster for team ${teamId}`);
    const response = await API.getTeamCurrentRoster(teamId);
    if (response.status === 200) {
      this.rosters[teamId] = response.body.players;
      this.rosterInView = response.body.players;
      this.teamInView.set(response.body.teamId);
      this.formMessage.set(`Found "${teamId}". Enter another?`)
    } else {
      this.rosters[teamId] = {};
      this.rosterInView = {};
      this.teamInView.set(false);
      this.formMessage.set(`Could not find "${teamId}"!`)
    }
  })
  */

  currentRoute = observable.box();
  homeMatchesTabInView = observable.box('default');

  leagues = observable([]);
  leaguesAreFetching = observable.box(false);
  leaguesAreFetched = observable.box(false);
  regions = observable([]);
  regionsAreFetching = observable.box(false);
  regionsAreFetched = observable.box(false);
  latestTournaments = observable([]);
  latestTournamentsAreFetching = observable.box(false);
  latestTournamentsAreFetched = observable.box(false);
  tournamentsByLeagueMap = observable.map({});
  tournamentsByLeagueAreFetching = observable.box(false);
  tournamentsByLeagueAreFetched = observable.box(false);
  latestTournamentsByLeagueMap = observable.map({});
  latestTournamentsByLeagueAreFetching = observable.box(false);
  latestTournamentsByLeagueAreFetched = observable.box(false);

  statsDefinitionsMap = observable.map({});
  statsDefinitionsAreFetching = observable.box(false);
  statsDefinitionsAreFetched = observable.box(false);
  statsAreFetching = observable.box(false);
  statsCurrentView = observable.box("");
  statsNavLeagueSelected = observable.box("");
  tournamentMetadataIsFetching = observable.box(false);
  tournamentIdInView = observable.box(null);
  tournamentNameInView = observable.box(null);
  tournamentLatestGameMap = observable({});
  statsCategoryInView = observable.box(null);
  statsInView = observable([]);
  filteredStatsInView = observable([]);
  statsColumnsInView = observable([]);
  currentStatsFilters = observable.object({});
  statsFiltersTeamPlaceholder = observable.box("Filter Teams");
  statsFiltersTeamOptions = observable([]);
  statsFiltersPlayerPlaceholder = observable.box("Filter Players");
  statsFiltersPlayerOptions = observable([]);
  statsFiltersLeaguePlaceholder = observable.box("Filter Leagues");
  statsFiltersLeagueOptions = observable([]);
  statsFiltersSelectedLeagues = observable([]);
  leagueFilterActivated = observable.box(false);
  statsFiltersPositionPlaceholder = observable.box("Filter Positions");
  soloQueueStatsFiltersPositionPlaceholder = observable.box("Select Position");
  soloQueueStatsFiltersChampionPlaceholder = observable.box("Select Champion");
  statsFiltersSelectedPositions = observable([]);
  statsFiltersSelectedTeams = observable([]);
  statsFiltersSelectedPlayers = observable([]);
  statsFiltersMinGP = observable.box("Min. GP");
  statsQueryFiltersChanged = observable.box(false);
  statsFiltersMapSide = observable.box("all");
  statsFiltersMapSideCurrentMultiselect = observable([
    { value: "all", id: "Both Sides" },
  ]);
  mapSideFilterActivated = observable.box(false);
  statsFiltersWinLoss = observable.box("all");
  statsFiltersWinLossCurrentMultiselect = observable([
    { value: "all", id: "All Results" },
  ])
  winLossFilterActivated = observable.box(false);
  statsFiltersPatchRangeFetching = observable.box(false);
  statsFiltersPatchRanges = observable({});
  statsFiltersPatchStart = observable.box(null);
  statsFiltersPatchStartCurrentMultiselect = observable([]);
  statsFiltersPatchEnd = observable.box(null);
  statsFiltersPatchEndCurrentMultiselect = observable([]);
  patchStartFilterActivated = observable.box(false);
  patchEndFilterActivated = observable.box(false);
  statsFiltersDateStart = observable.box(null);
  statsFiltersDateEnd = observable.box(null);
  dateFilterActivated = observable.box(false);
  soloQueueStatsFiltersPatchRangeFetching = observable.box(false);
  soloQueueStatsFiltersPatchRangeFetched = observable.box(false);
  soloQueueStatsFiltersPatchRange = observable([]);
  soloQueueStatsFiltersPatch = observable.box(null);
  soloQueuePatchFilterActivated = observable.box(false);
  isSoloQueueChampionListFetching = observable.box(false);
  isSoloQueueChampionListFetched = observable.box(false);
  soloQueueChampions = observable({});
  soloQueuePositionFilterActivated = observable.box(false);
  soloQueueStatsFiltersChampionList = observable([]);
  soloQueueStatsFiltersPosition = observable.box(null);
  soloQueueStatsFiltersChampions = observable([]);
  soloQueueQueryFiltersChanged = observable.box(false);
  explorerPatchRangeIsReady = observable.box(false);
  explorerDefaultLeaguesAreReady = observable.box(false);
  explorerInitialStatsAreFetched = observable.box(false);
  sortColumn = observable.box("");
  sortOrder = observable.box(0);
  playerStatsMap = observable.map({});
  teamStatsMap = observable.map({});
  championStatsMap = observable.map({});
  soloQueueMatchupStatsMap = observable.map({});

  gamesAreFetching = observable.box(false);
  gamesAreFetched = observable.box(false);
  gamesInView = observable([]);
  filteredGamesInView = observable([]);

  dataLinksAreFetching = observable.box(false);
  dataLinksAreFetched = observable.box(false);
  matchDataLinks = observable([]);

  /**
   * Fetch list of match data links.
   */
  setMatchDataLinks = action(async () => {
    this.dataLinksAreFetching.set(true);
    const response = await API.getMatchDataDownloadList();
    if (response.status === 200) {
      this.matchDataLinks.replace(response.body);
    } else {
      console.log("Unable to fetch list of match data links");
    }
    this.dataLinksAreFetched.set(true);
    this.dataLinksAreFetching.set(false);
  });

  setCurrentRoute = action((route) => {
    this.currentRoute.set(route);
  });

  setHomeMatchesTabInView = action((tab) => {
    this.homeMatchesTabInView.set(tab);
  })

  setStatsCurrentView = action((view) => {
    this.statsCurrentView.set(view);
  });

  /**
   * Fetch list of leagues.
   */
  setLeagues = action(async () => {
    this.leaguesAreFetching.set(true);
    const response = await API.getLeagues();
    if (response.status === 200) {
      this.leagues.replace(response.body);
      this.statsFiltersLeagueOptions.replace(
        response.body.map((league) => {
          return { name: league.name, id: league.id };
        })
      );
    } else {
      console.log("Unable to fetch list of leagues");
    }
    this.leaguesAreFetched.set(true);
    this.leaguesAreFetching.set(false);
  });

  /**
   * Fetch list of regions.
   */
  setLeagueRegions = action(async () => {
    this.regionsAreFetching.set(true);
    const response = await API.getLeagueRegions();
    if (response.status === 200) {
      this.regions.replace(response.body);
    } else {
      console.log("Unable to fetch list of league regions");
    }
    this.regionsAreFetched.set(true);
    this.regionsAreFetching.set(false);
  });

  /**
   * Fetch list of latest tournaments for generating links.
   */
  setListOfLatestTournaments = action(async () => {
    this.latestTournamentsAreFetching.set(true);
    const response = await API.getLatestTournaments();
    if (response.status === 200) {
      this.latestTournaments.replace(response.body);
    } else {
      console.log("Unable to fetch list of latest tournaments");
    }
    this.latestTournamentsAreFetched.set(true);
    this.latestTournamentsAreFetching.set(false);
  });

  /**
   * Fetch latest tournament for each league.
   */
  setLatestTournamentsByLeague = action(async () => {
    this.latestTournamentsByLeagueAreFetching.set(true);
    const response = await API.getLatestTournamentsByLeague();
    if (response.status === 200) {
      let latestTournamentsByLeague = {};
      for (let league of response.body) {
        latestTournamentsByLeague[league.leagueId] = league;
      }
      this.latestTournamentsByLeagueMap = latestTournamentsByLeague;
    } else {
      console.log(`Unable to fetch latest tournaments per league`);
    }
    this.latestTournamentsByLeagueAreFetched.set(true);
    this.latestTournamentsByLeagueAreFetching.set(false);
  });

  /**
   * Fetch full list of tournaments for each league.
   */
  setTournamentsByLeague = action(async () => {
    this.tournamentsByLeagueAreFetching.set(true);
    const response = await API.getTournamentsByLeague();
    if (response.status === 200) {
      this.tournamentsByLeagueMap = response.body;
    } else {
      console.log(`Unable to fetch tournaments by league`);
    }
    this.tournamentsByLeagueAreFetched.set(true);
    this.tournamentsByLeagueAreFetching.set(false);
  });

  setStatsNavLeagueSelected = action((leagueId) => {
    this.statsNavLeagueSelected.set(leagueId);
  });

  setStatsCategoryInView = action((category) => {
    this.statsCategoryInView.set(category);
  });

  setTournamentIdInView = action((tournamentId) => {
    this.tournamentIdInView.set(tournamentId);
  });

  /**
   * Use tournament ID to get name of tournament currently in view.
   * @param {*} category, e.g. players, teams, champions
   * @param {*} tournamentId, ID of tournament
   * @param {*} columnSet, optional, 'china' for limited columns from lol.QQ.com data
   * @param {*} format, optional, 'json' to get JSON-formatted results
   */
  setTournamentMetadataInView = action(async (tournamentId) => {
    this.tournamentMetadataIsFetching.set(true);
    const response = await API.getTournamentMetadata(tournamentId);
    if (response.status === 200 && response.body.length > 0) {
      this.tournamentNameInView.set(response.body[0].name);
      this.tournamentLatestGameMap[tournamentId] = response.body[0].latestGame;
      const startDate = moment(response.body[0].startDate);
      const endDate = moment.utc(response.body[0].latestGame);
      this.setDateRangeFilter(startDate, endDate);
    } else {
      this.tournamentNameInView.set("Unable to fetch tournament with this ID");
      this.tournamentLatestGameMap[tournamentId] = false;
    }
    this.tournamentMetadataIsFetching.set(false);
  });

  quickAccessUsingDefault = observable.box(true);
  quickAccessInView = observable.box(null);

  setQuickAccessInView = action((quickAccessDto) => {
    this.quickAccessInView.set(quickAccessDto);
  })

  getStatsDefinitions = action(async () => {
    this.statsDefinitionsAreFetching.set(true);
    const response = await API.getStatsDefinitions();
    if (response.status === 200) {
      for (let stat of response.body) {
        this.statsDefinitionsMap.set(stat.label, stat.definition);
      }
    } else {
      console.log("Could not fetch stats definitions");
    }
    this.statsDefinitionsAreFetched.set(true);
    this.statsDefinitionsAreFetching.set(false);
  });

  /**
   * Query a set of stats.
   * @param {*} category, e.g. players, teams, champions
   * @param {*} tournamentId, ID of tournament
   * @param {*} columnSet, optional, 'china' for limited columns from lol.QQ.com data
   * @param {*} format, optional, 'json' to get JSON-formatted results
   */
  setStatsInView = action(
    async (
      view,
      category,
      tournamentId = null,
      playerId = null,
      teamId = null,
      columnSet = null,
      format = null,
      queryFilters
    ) => {
      this.statsAreFetching.set(true);
      let statsNeedFetching = true;
      var statsDto = [];

      for (var propName in queryFilters) {
        if (
          queryFilters[propName] === null ||
          queryFilters[propName] === undefined
        ) {
          delete queryFilters[propName];
        }
      }

      const filterString = JSON.stringify(queryFilters);

      /* WIP switch to use a map to "cache" stats, but will be tricky with different query filter configurations
    switch (category) {
      case 'players':
        if (tournamentId in this.playerStatsMap) {
          statsNeedFetching = false;
          this.statsInView.replace(this.playerStatsMap[tournamentId]);
        }
        break;
      case 'teams':
        if (tournamentId in this.teamStatsMap) {
          statsNeedFetching = false;
          this.statsInView.replace(this.teamStatsMap[tournamentId]);
        }
        break;
      case 'champions':
        const response = await API.getPlayerStatsForTournament(tournamentId, columnSet, format);
        break;
      default:
        this.statsAreFetching.set(false);
        throw new Error(`Not a valid stats category`);
    }
    */

      if (statsNeedFetching) {
        this.statsQueryFiltersChanged.set(false);
        this.soloQueueQueryFiltersChanged.set(false);

        var response = null;

        if (view === "byTournament") {
          switch (category) {
            case "players":
              response = await API.getPlayerStatsForTournament(
                tournamentId,
                columnSet,
                format,
                queryFilters
              );
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.playerStatsMap[tournamentId] = statsDto;
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.setTeamFilterOptions();
                this.setPlayerFilterOptions();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(`Could not fetch player stats for ${tournamentId}`);
                this.statsColumnsInView.replace([]);
              }
              break;
            case "teams":
              response = await API.getTeamStatsForTournament(
                tournamentId,
                columnSet,
                format,
                queryFilters
              );
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.teamStatsMap[tournamentId] = statsDto;
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.setTeamFilterOptions();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(`Could not fetch team stats for ${tournamentId}`);
                this.statsColumnsInView.replace([]);
              }
              break;
            case "champions":
              queryFilters["tournaments"] = tournamentId;
              response = await API.getChampionStatsForTournament(
                columnSet,
                queryFilters
              );
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.championStatsMap[tournamentId] = statsDto;
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch champion stats for ${tournamentId}`
                );
                this.statsColumnsInView.replace([]);
              }
              break;
            default:
              this.statsAreFetching.set(false);
              throw new Error(`Not a valid stats category`);
          }
        }

        if (view === "explorer") {
          switch (category) {
            case "champions":
              response = await API.getChampionStatsForExplorer(queryFilters);
              if (response.status === 200) {
                statsDto = response.body;
                this.championStatsMap[filterString] = statsDto;
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(`Could not fetch champion stats for explorer: ${response.status}`);
                this.statsColumnsInView.replace([]);
              }
              this.explorerInitialStatsAreFetched.set(true);
              break;
            default:
              this.statsAreFetching.set(false);
              throw new Error(`Not a valid stats category`);
          }
        }

        if (view === "soloQueueMatchups") {
          switch (category) {
            case "champions":
              response = await API.getSoloQueueMatchupStats(queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.soloQueueMatchupStatsMap[filterString] = statsDto;
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch champion matchup stats for solo queue matchups`
                );
                this.statsColumnsInView.replace([]);
              }
              break;
            default:
              this.statsAreFetching.set(false);
              throw new Error(`Not a valid stats category`);
          }
        }

        if (view === "playerProfile") {
          switch (category) {
            case "statsByYear":
              response = await API.getPlayerCareerStatsByYear(playerId, queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.setPlayerCareerStatsByYear(playerId, statsDto);
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch player stats by year`
                );
                this.setPlayerCareerStatsByYear(playerId, []);
                this.statsColumnsInView.replace([]);
              }
              break;
            case "statsBySplit":
              response = await API.getPlayerCareerStatsBySplit(playerId, queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.setPlayerCareerStatsBySplit(playerId, statsDto);
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch player stats by split`
                );
                this.setPlayerCareerStatsBySplit(playerId, []);
                this.statsColumnsInView.replace([]);
              }
              break;
            case "championPool":
              response = await API.getPlayerCareerChampionPool(playerId, queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.setPlayerChampionPool(playerId, statsDto);
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch player champion pool`
                );
                this.setPlayerChampionPool(playerId, []);
                this.statsColumnsInView.replace([]);
              }
              break;
            default:
              this.statsAreFetching.set(false);
              throw new Error(`Not a valid playerProfile category`);
          }
        }

        if (view === "teamProfile") {
          switch (category) {
            case "statsByYear":
              response = await API.getTeamStatsByYear(teamId, queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.setTeamStatsByYear(teamId, statsDto);
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch team stats by year`
                );
                this.setTeamStatsByYear(teamId, []);
                this.statsColumnsInView.replace([]);
              }
              break;
            case "statsBySplit":
              response = await API.getTeamStatsBySplit(teamId, queryFilters);
              if (response.status === 200 && response.body.length > 0) {
                statsDto = response.body;
                this.setTeamStatsBySplit(teamId, statsDto);
                this.statsInView.replace(statsDto);
                this.setStatsColumnsInView();
                this.filterStatsTable(this.currentStatsFilters);
              } else {
                console.log(
                  `Could not fetch team stats by split`
                );
                this.setTeamStatsBySplit(teamId, []);
                this.statsColumnsInView.replace([]);
              }
              break;
            default:
              this.statsAreFetching.set(false);
              throw new Error(`Not a valid teamProfile category`);
          }
        }
      }

      this.statsAreFetching.set(false);
    }
  );

  setPatchRange = action(async ({ tournament = null, playerId = null }) => {
    this.statsFiltersPatchRangeFetching.set(true);
    var segment = "all";
    if (tournament) {
      segment = tournament;
    }
    if (playerId) {
      segment = playerId;
    }

    const response = await API.getPatchRange({ tournament: tournament, player: playerId });
    this.statsFiltersPatchRanges[segment] = response.body;
    if (segment === "all") {
      this.explorerPatchRangeIsReady.set(true);
    }
    this.statsFiltersPatchRangeFetching.set(false);
  });

  setSoloQueuePatchRange = action(async () => {
    this.soloQueueStatsFiltersPatchRangeFetching.set(true);
    const response = await API.getSoloQueuePatchRange();
    this.soloQueueStatsFiltersPatchRange = response.body;
    this.soloQueueStatsFiltersPatch.set(response.body[0].patch);
    this.soloQueueStatsFiltersPatchRangeFetched.set(true);
    this.soloQueueStatsFiltersPatchRangeFetching.set(false);
  });

  setSoloQueueChampionsForPatch = action(async (patch) => {
    this.isSoloQueueChampionListFetching.set(true);
    const response = await API.getSoloQueueChampionsForPatch(patch);
    this.soloQueueChampions[patch] = response.body;
    this.isSoloQueueChampionListFetched.set(true);
    this.isSoloQueueChampionListFetching.set(false);
  });

  setSortColumn = action((column) => {
    this.sortColumn.set(column);
  });

  setSortOrder = action((order) => {
    this.sortOrder.set(order);
  });

  sortStatsInView = action(async () => {
    let sortColumn = this.sortColumn.get();
    let sortOrder = this.sortOrder.get();
    let invertSort = false;
    if (sortColumn === 'Rank') {
      invertSort = true;
    }

    if (sortOrder === 0) {
      sortOrder = -1;
    }

    debug(
      `Sorting stats by ${sortColumn} in sortOrder ${sortOrder === 1 ? "asc" : "desc"
      }`
    );

    let statsCopy = [...toJS(this.filteredStatsInView)];
    statsCopy.sort((rowA, rowB) => {
      let comparison = 0;
      if (rowA[sortColumn] === null || rowA[sortColumn] === "-") {
        return 1;
      }
      if (rowB[sortColumn] === null || rowB[sortColumn] === "-") {
        return -1;
      }

      /* Uppercase strings for sorting */
      let rowAValue =
        typeof rowA[sortColumn] === "string"
          ? rowA[sortColumn].toUpperCase()
          : rowA[sortColumn];
      let rowBValue =
        typeof rowB[sortColumn] === "string"
          ? rowB[sortColumn].toUpperCase()
          : rowB[sortColumn];

      /* Convert percentages to floats for sorting */
      if (
        typeof rowA[sortColumn] === "string" &&
        rowA[sortColumn].slice(-1) === "%"
      ) {
        rowAValue = parseFloat(rowAValue.slice(0, -1));
        rowBValue = parseFloat(rowBValue.slice(0, -1));
      }

      if (
        typeof rowA[sortColumn] === "string" &&
        rowA[sortColumn].slice(-1) !== "%"
      ) {
        invertSort = true;
      }

      if (rowAValue > rowBValue) {
        comparison = 1;
      } else if (rowAValue < rowBValue) {
        comparison = -1;
      }
      return comparison * sortOrder * (invertSort ? -1 : 1);
    });

    this.filteredStatsInView.replace(statsCopy);
  });

  setStatsColumnsInView = action(async () => {
    if (this.statsInView.length > 0) {
      var statsColumns = Object.keys(this.statsInView[0]);
      if (statsColumns[0] === "id") {
        statsColumns.shift();
      }
      this.statsColumnsInView.replace(statsColumns);
    } else {
      this.statsColumnsInView.replace([])
    }
  });

  setTeamFilterOptions = action(async () => {
    this.statsFiltersTeamOptions.replace(
      [...new Set(this.statsInView.map((player) => player.Team))].sort((a, b) =>
        a.localeCompare(b, "en", { sensitivity: "base" })
      )
    );
  });

  setPlayerFilterOptions = action(async () => {
    this.statsFiltersPlayerOptions.replace(
      [...new Set(this.statsInView.map((player) => player.Player))]
        .filter((player) => player)
        .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }))
    );
  });

  setMapSideFilter = action(async (mapSide) => {
    this.statsQueryFiltersChanged.set(
      mapSide[0].value !== this.statsFiltersMapSide.get() ? true : false
    );
    this.statsFiltersMapSide.set(mapSide[0].value);
    this.statsFiltersMapSideCurrentMultiselect.replace(mapSide)
    this.mapSideFilterActivated.set(true);
  });

  setWinLossFilter = action(async (winLoss) => {
    this.statsQueryFiltersChanged.set(
      winLoss[0].value !== this.statsFiltersWinLoss.get() ? true : false
    );
    this.statsFiltersWinLoss.set(winLoss[0].value);
    this.statsFiltersWinLossCurrentMultiselect.replace(winLoss);
    this.winLossFilterActivated.set(true);
  });

  setPatchStartFilter = action(async (patchStart) => {
    this.statsQueryFiltersChanged.set(
      patchStart[0].value !== this.statsFiltersPatchStart.get() ? true : false
    );
    this.statsFiltersPatchStart.set(patchStart[0].value);
    this.statsFiltersPatchStartCurrentMultiselect.replace(patchStart)
    this.patchStartFilterActivated.set(true);
  });
  setPatchEndFilter = action(async (patchEnd) => {
    this.statsQueryFiltersChanged.set(
      patchEnd[0].value !== this.statsFiltersPatchEnd.get() ? true : false
    );
    this.statsFiltersPatchEnd.set(patchEnd[0].value);
    this.statsFiltersPatchEndCurrentMultiselect.replace(patchEnd)
    this.patchEndFilterActivated.set(true);
  });
  setSoloQueuePatch = action(async (patch) => {
    this.statsQueryFiltersChanged.set(
      patch !== this.soloQueueStatsFiltersPatch.get() ? true : false
    );
    this.soloQueueStatsFiltersPatch.set(patch);
    this.soloQueuePatchFilterActivated.set(true);
  });

  setSoloQueueChampions = action(async (selectedChampions) => {
    this.soloQueueQueryFiltersChanged.set(true);
    this.soloQueueStatsFiltersChampions = selectedChampions;
  });

  setDateRangeFilter = action(async (startDate, endDate) => {
    this.statsQueryFiltersChanged.set(
      startDate !== this.statsFiltersDateStart.get() ||
        endDate !== this.statsFiltersDateEnd.get()
        ? true
        : false
    );
    this.statsFiltersDateStart.set(startDate);
    this.statsFiltersDateEnd.set(endDate);
    this.dateFilterActivated.set(true);
  });

  setLeagueFilter = action(async (submittedLeagues) => {
    this.statsQueryFiltersChanged.set(
      submittedLeagues !== this.statsFiltersSelectedLeagues ? true : false
    );
    this.statsFiltersSelectedLeagues.replace(submittedLeagues);
    this.leagueFilterActivated.set(true);
  });

  resetSort = action(() => {
    this.sortColumn.set("");
    this.sortOrder.set(0);
  });

  resetBasicFilters = action(() => {
    this.statsFiltersSelectedPositions = [];
    this.statsFiltersSelectedTeams = [];
    this.statsFiltersSelectedPlayers = [];
    this.statsFiltersMinGP.set("Min. GP");
    this.currentStatsFilters = {};
  });

  resetQueryFilters = action(() => {
    this.statsFiltersMapSide.set("all");
    this.statsFiltersMapSideCurrentMultiselect.replace([
      { value: "all", id: "Both Sides" },
    ]);
    this.mapSideFilterActivated.set(false);
    this.statsFiltersWinLoss.set("all");
    this.statsFiltersWinLossCurrentMultiselect.replace([
      { value: "all", id: "All Results" },
    ])
    this.winLossFilterActivated.set(false);
    this.statsFiltersPatchStart.set(null);
    this.statsFiltersPatchStartCurrentMultiselect.replace([])
    this.statsFiltersPatchEnd.set(null);
    this.statsFiltersPatchEndCurrentMultiselect.replace([])
    this.patchStartFilterActivated.set(false);
    this.patchEndFilterActivated.set(false);
    this.statsFiltersDateStart.set(null);
    this.statsFiltersDateEnd.set(null);
    this.dateFilterActivated.set(false);
    this.statsFiltersSelectedLeagues.replace([]);
    this.explorerDefaultLeaguesAreReady.set(false);
    this.leagueFilterActivated.set(false);
    // this.explorerPatchRangeIsReady.set(false);
    this.statsQueryFiltersChanged.set(true);
  });
  resetSoloQueueQueryFilters = action(() => {
    const mostRecentPatch = this.soloQueueStatsFiltersPatchRangeFetched.get()
      ? this.soloQueueStatsFiltersPatchRange[0].patch
      : null;
    this.soloQueueStatsFiltersPatch.set(mostRecentPatch);
    this.soloQueuePatchFilterActivated.set(false);
    this.soloQueueStatsFiltersChampions = [];
    this.soloQueueStatsFiltersPosition.set(null);
    this.soloQueuePositionFilterActivated.set(false);
    this.soloQueueQueryFiltersChanged.set(true);
  });

  filterStatsTable = action(async (filters) => {
    this.currentStatsFilters = filters;

    var filtered = [];

    if (Object.keys(filters).length > 0) {
      for (let row of this.statsInView) {
        if (
          (this.statsCategoryInView.get() === "teams" ||
            filters.positions.includes(row.Pos) ||
            filters.positions.length === 0) &&
          (this.statsCategoryInView.get() === "teams" ||
            row.GP >= filters.minGP ||
            filters.minGP === 0) &&
          (this.statsCategoryInView.get() !== "players" ||
            filters.players.includes(row.Player) ||
            filters.players.length === 0) &&
          (this.statsCategoryInView.get() === "champions" ||
            filters.teams.includes(row.Team) ||
            filters.teams.length === 0)
        ) {
          filtered.push(row);
        }
      }
      this.filteredStatsInView.replace(filtered);
      await this.sortStatsInView();
    } else {
      this.filteredStatsInView.replace(this.statsInView);
      await this.sortStatsInView();
    }
  });

  blogFetching = observable.box(false);
  blogPageInView = observable.box(1);
  articlesInView = observable([]);
  articleMap = observable.map({});
  singleArticleInView = observable.box(null);
  slugInView = observable.box(null);
  idOfSlugInView = observable.box(null);
  blogPagesFetched = observable.map({});
  searchesFetched = observable.map({});
  categoriesFetched = observable.map({});
  blogPageCount = observable.box("");
  blogSearchPageCount = observable.map({});
  blogCategoryPageCount = observable.map({});
  newNews = observable.box('unfetched');

  fetchArticles = action(async (page = 1) => {
    page = parseInt(page);
    this.blogFetching.set(true);
    const response = await BlogAPI.getArticles(page);
    if (response.status === 200) {
      let articleIds = [...new Set(response.body.map((article) => article.id))];
      for (let article of response.body) {
        this.articleMap[article.id] = constructArticleForStore(article);
      }
      this.setArticlesInView(articleIds);
      this.blogPageInView.set(
        parseInt(response.headers["x-wp-totalpages"]) < page
          ? parseInt(response.headers["x-wp-totalpages"])
          : page
      );
      this.blogPageCount.set(parseInt(response.headers["x-wp-totalpages"]));
      this.blogPagesFetched[
        parseInt(response.headers["x-wp-totalpages"]) < page
          ? parseInt(response.headers["x-wp-totalpages"])
          : page
      ] = articleIds;
    }
    this.blogFetching.set(false);
  });

  fetchSearch = action(async (searchTerm, page = 1) => {
    page = parseInt(page);
    this.blogFetching.set(true);
    const response = await BlogAPI.getSearch(searchTerm, page);
    var articleIds = [];
    if (response.status === 200 && response.headers && response.body) {
      if (response.body.length > 0) {
        articleIds = [...new Set(response.body.map((article) => article.id))];
        for (let article of response.body) {
          this.articleMap[article.id] = constructArticleForStore(article);
        }
        this.setArticlesInView(articleIds);
        this.blogPageInView.set(
          parseInt(response.headers["x-wp-totalpages"]) < page
            ? parseInt(response.headers["x-wp-totalpages"])
            : page
        );
        this.blogSearchPageCount[searchTerm] = parseInt(
          response.headers["x-wp-totalpages"]
        );
      }
      if (!(searchTerm in this.searchesFetched)) {
        this.searchesFetched[searchTerm] = {};
      }
      this.searchesFetched[searchTerm][
        parseInt(response.headers["x-wp-totalpages"]) < page
          ? parseInt(response.headers["x-wp-totalpages"])
          : page
      ] = articleIds;
    } else {
      if (!(searchTerm in this.searchesFetched)) {
        this.searchesFetched[searchTerm] = {};
      }
      this.searchesFetched[searchTerm][page] = [];
    }
    this.blogFetching.set(false);
  });

  fetchCategory = action(async (category, page = 1, newsCheck = false) => {
    page = parseInt(page);
    this.blogFetching.set(true);
    if (newsCheck) {
      this.newNews.set('fetching');
    }
    const response = await BlogAPI.getArticles(page, category);
    if (response.status === 200 && response.headers && response.body) {
      let articleIds = [...new Set(response.body.map((article) => article.id))];
      for (let article of response.body) {
        this.articleMap[article.id] = constructArticleForStore(article);
      }
      if (!newsCheck) {
        this.setArticlesInView(articleIds);
        this.blogPageInView.set(
          parseInt(response.headers["x-wp-totalpages"]) < page
            ? parseInt(response.headers["x-wp-totalpages"])
            : page
        );
      }
      this.blogCategoryPageCount[category] = parseInt(
        response.headers["x-wp-totalpages"]
      );
      if (!(category in this.categoriesFetched)) {
        this.categoriesFetched[category] = {};
      }
      this.categoriesFetched[category][
        parseInt(response.headers["x-wp-totalpages"]) < page
          ? parseInt(response.headers["x-wp-totalpages"])
          : page
      ] = articleIds;

      if (newsCheck) {
        if (moment.utc().diff(moment(response.body[0].date_gmt), 'days') < 7) {
          this.newNews.set('yes');
        } else {
          this.newNews.set('no');
        }
      }
    }

    this.blogFetching.set(false);
  });

  fetchSingleArticle = action(async (id) => {
    this.blogFetching.set(true);
    const response = await BlogAPI.getSingleArticle(id);
    if (response.status === 200) {
      this.articleMap[response.body.id] = constructArticleForStore(
        response.body
      );
      this.setArticlesInView(id, true);
    }
    this.blogFetching.set(false);
  });

  fetchSingleArticleBySlug = action(async (slug) => {
    this.blogFetching.set(true);
    const response = await BlogAPI.getSingleArticleBySlug(slug);
    if (response.status === 200) {
      this.slugInView.set(slug);
      this.idOfSlugInView.set(response.body[0].id);
      this.articleMap[response.body[0].id] = constructArticleForStore(
        response.body[0]
      );
      this.setArticlesInView(response.body[0].id, true);
    }
    this.blogFetching.set(false);
  });

  setArticlesInView = action((idPayload, single = false) => {
    if (single) {
      this.singleArticleInView.set(idPayload);
    } else {
      this.articlesInView.replace(idPayload);
    }
  });

  egrCoefficientsFetched = observable.box(false);
  egrCoefficients = observable({});
  egrSplitSelected = observable.box("");
  egrSide = observable.box(0);
  egrGoldDiff = observable.box(0);
  egrInfernalDiff = observable.box(0);
  egrMountainDiff = observable.box(0);
  egrOceanDiff = observable.box(0);
  egrCloudDiff = observable.box(0);
  egrChemtechDiff = observable.box(0);
  egrHextechDiff = observable.box(0);

  setEgrCoefficients = action((egrCoefficients) => {
    let setInitialSplit = 0;
    for (let split of egrCoefficients) {
      const splitLabel =
        split.split.substring(split.split.length - 4, split.split.length) +
        " " +
        split.split.substring(0, split.split.length - 4);
      this.egrCoefficients[splitLabel] = split;
      if (setInitialSplit === 0) {
        this.egrSplitSelected.set(splitLabel);
      }
      setInitialSplit += 1;
    }
    this.egrCoefficientsFetched.set(true);
  });
  setEgrSplit = action((split) => {
    this.egrSplitSelected.set(split);
  });
  setEgrSide = action((side) => {
    this.egrSide.set(side);
  });
  setEgrGoldDiff = action((number) => {
    this.egrGoldDiff.set(number);
  });

  egrReset = action(() => {
    this.egrSide.set(0);
    this.egrGoldDiff.set(0);
    this.egrInfernalDiff.set(0);
    this.egrMountainDiff.set(0);
    this.egrCloudDiff.set(0);
    this.egrOceanDiff.set(0);
    this.egrChemtechDiff.set(0);
    this.egrHextechDiff.set(0);
  });

  playersAreFetching = observable.box(false);
  playersInView = observable([]);
  playerSearchesFetched = observable.map({});
  playerMap = observable.map({});

  fetchPlayers = action(async (searchTerm) => {
    this.playersAreFetching.set(true);
    const response = await API.searchPlayers(searchTerm);
    var playerIds = [];
    if (response.length > 0) {
      playerIds = [...new Set(response.map((player) => player.playerId))];
      for (let player of response) {
        this.playerMap[player.playerId] = player;
      }
      this.setPlayersInView(playerIds);

      if (!(searchTerm in this.playerSearchesFetched)) {
        this.playerSearchesFetched[searchTerm] = {};
      }
      this.playerSearchesFetched[searchTerm] = playerIds;
    } else {
      if (!(searchTerm in this.playerSearchesFetched)) {
        this.playerSearchesFetched[searchTerm] = {};
      }
      this.playerSearchesFetched[searchTerm] = [];
      this.setPlayersInView([]);
    }

    this.playersAreFetching.set(false);
  });

  setPlayersInView = action((idPayload) => {
    this.playersInView.replace(idPayload);
  });

  playerProfileFetching = observable.box(false);
  playerProfileInView = observable.box(null);
  playerProfileMap = observable.map({});
  playerCareerStatsBySplitFetching = observable.box(false);
  playerProfileDataMap = observable.map({});

  playerProfileTabInView = observable.box('statsByYear');

  addPlayerProfileData = action((playerId, playerProfileData) => {
    this.playerProfileMap[playerId] = playerProfileData;
  })

  setPlayerProfileInView = action((playerId) => {
    this.playerProfileInView.set(playerId);
  })

  setPlayerProfileTabInView = action((tab) => {
    this.playerProfileTabInView.set(tab);
  })

  addPlayerToProfileDataMap = action((playerId) => {
    this.playerProfileDataMap[playerId] = {};
  })

  addPlayerUpcomingMatches = action((playerId, playerUpcomingMatches) => {
    this.playerProfileDataMap[playerId].upcomingMatches = playerUpcomingMatches;
  })

  setPlayerGameDetails = action(async (playerId, games) => {
    this.playerProfileDataMap[playerId].gameDetails = games;
    // Extend this to be paginated, keyed to a string-flattened set of query terms and a page number
  });

  setPlayerCareerStatsByYear = action((playerId, careerStatsByYear) => {
    this.playerProfileDataMap[playerId].careerStatsByYear = careerStatsByYear;
  })

  setPlayerCareerStatsBySplit = action((playerId, careerStatsBySplit) => {
    this.playerProfileDataMap[playerId].careerStatsBySplit = careerStatsBySplit;
  })

  setPlayerChampionPool = action((playerId, championPool) => {
    this.playerProfileDataMap[playerId].careerChampionPool = championPool;
  })

  teamsAreFetching = observable.box(false);
  teamsInView = observable([]);
  teamSearchesFetched = observable.map({});
  teamMap = observable.map({});

  fetchTeams = action(async (searchTerm) => {
    this.teamsAreFetching.set(true);
    const response = await API.searchTeams(searchTerm);
    var teamIds = [];
    if (response.length > 0) {
      teamIds = [...new Set(response.map((team) => team.teamId))];
      for (let team of response) {
        this.teamMap[team.teamId] = team;
      }
      this.setTeamsInView(teamIds);

      if (!(searchTerm in this.teamSearchesFetched)) {
        this.teamSearchesFetched[searchTerm] = {};
      }
      this.teamSearchesFetched[searchTerm] = teamIds;
    } else {
      if (!(searchTerm in this.teamSearchesFetched)) {
        this.teamSearchesFetched[searchTerm] = {};
      }
      this.teamSearchesFetched[searchTerm] = [];
      this.setTeamsInView([]);
    }

    this.teamsAreFetching.set(false);
  });

  setTeamsInView = action((idPayload) => {
    this.teamsInView.replace(idPayload);
  });

  teamProfileFetching = observable.box(false);
  teamProfileInView = observable.box(null);
  teamProfileMap = observable.map({});
  teamProfileDataMap = observable.map({});

  teamProfileTabInView = observable.box('statsBySplit');

  addTeamProfileData = action((teamId, teamProfileData) => {
    this.teamProfileMap[teamId] = teamProfileData;
  })

  addTeamUpcomingMatches = action((teamId, teamUpcomingMatches) => {
    this.teamProfileDataMap[teamId].upcomingMatches = teamUpcomingMatches;
  })

  setTeamProfileInView = action((teamId) => {
    this.teamProfileInView.set(teamId);
  })

  setTeamProfileTabInView = action((tab) => {
    this.teamProfileTabInView.set(tab);
  })

  addTeamToProfileDataMap = action((teamId) => {
    this.teamProfileDataMap[teamId] = {};
  })

  setTeamStatsByYear = action((teamId, statsByYear) => {
    this.teamProfileDataMap[teamId].statsByYear = statsByYear;
  })

  setTeamStatsBySplit = action((teamId, statsBySplit) => {
    this.teamProfileDataMap[teamId].statsBySplit = statsBySplit;
  })

  setTeamGameDetails = action(async (teamId, games) => {
    if (!this.teamProfileDataMap[teamId]) {
      this.teamProfileDataMap[teamId] = {};
    }
    this.teamProfileDataMap[teamId].gameDetails = games;
    // Extend this to be paginated, keyed to a string-flattened set of query terms and a page number
  });

  setTeamGameDetailsForDatetime = action(async (teamId, games, beforeTime) => {
    if (!this.teamProfileDataMap[teamId]) {
      this.teamProfileDataMap[teamId] = {};
    }
    if (!this.teamProfileDataMap[teamId].gameDetailsBeforeTime) {
      this.teamProfileDataMap[teamId].gameDetailsBeforeTime = {};
    }
    this.teamProfileDataMap[teamId].gameDetailsBeforeTime[beforeTime] = games;
    // Extend this to be paginated, keyed to a string-flattened set of query terms and a page number
  });

  fetchGameDetails = action(async (type, id, filters = {}) => {
    if (["matchPreview", "completedMatch"].includes(type)) {
      this.gamesAreFetching.set(true);
    }
    var response = null;

    switch (type) {
      case "playerProfile":
        response = await API.getPlayerGameDetails(id);
        if (response.status === 200 && response.body.length > 0) {
          const gamesDto = response.body;
          this.setPlayerGameDetails(id, gamesDto);
          this.gamesInView.replace(gamesDto);
          this.gamesAreFetched.set(true);
        } else {
          console.log(
            `Could not fetch any player game details`
          );
          this.setPlayerGameDetails(id, []);
          this.gamesInView.replace([]);
          this.gamesAreFetched.set(true);
        }

        this.gamesAreFetching.set(false);
        break;
      case "teamProfile":
        response = await API.getTeamGameDetails(id);
        if (response.status === 200 && response.body.length > 0) {
          const gamesDto = response.body;
          this.setTeamGameDetails(id, gamesDto);
          this.gamesInView.replace(gamesDto);
          this.gamesAreFetched.set(true);
        } else {
          console.log(
            `Could not fetch any team game details`
          );
          this.setTeamGameDetails(id, []);
          this.gamesInView.replace([]);
          this.gamesAreFetched.set(true);
        }

        this.gamesAreFetching.set(false);
        break;
      case "matchPreview":
        response = await API.getTeamGameDetails(id);
        if (response.status === 200 && response.body.length > 0) {
          const gamesDto = response.body;
          this.setTeamGameDetails(id, gamesDto);
          this.gamesAreFetching.set(false);
          return true;
        } else {
          console.log(
            `Could not fetch any team game details`
          );
          this.setTeamGameDetails(id, []);
          this.gamesAreFetching.set(false);
          return true;
        }
      case "completedMatch":
        response = await API.getTeamGameDetails(id, { beforeTime: filters.beforeTime });
        if (response.status === 200 && response.body.length > 0) {
          const gamesDto = response.body;
          this.setTeamGameDetailsForDatetime(id, gamesDto, filters.beforeTime);
          this.gamesAreFetching.set(false);
          return true;
        } else {
          console.log(
            `Could not fetch any team game details before datetime ${filters.beforeTime}`
          );
          this.setTeamGameDetailsForDatetime(id, [], filters.beforeTime);
          this.gamesAreFetching.set(false);
          return true;
        }

      default:
        this.statsAreFetching.set(false);
        throw new Error(`Not a valid stats category`);
    }
  });

  draftsAreFetching = observable.box(false);
  draftsMapSideInView = observable.box('all');
  matchDraftsTeamInView = observable.box('1');
  teamDrafts = observable.map({});

  getTeamDrafts = action(async (teamId, filters = {}) => {
    const response = await API.getTeamDrafts(teamId, filters);
    if (response.status === 200 && response.body.length > 0) {
      const draftsDto = response.body;
      this.setTeamDrafts(teamId, draftsDto, filters);
      return true;
    } else {
      this.setTeamDrafts(teamId, [], filters);
      return false;
    }
  });

  setTeamDrafts = action(async (teamId, drafts, filters = {}) => {
    if (!filters.before) {
      filters.before = 'now';
    }
    this.teamDrafts[`${teamId}-${JSON.stringify(filters)}`] = drafts;

    // Extend this to be paginated, keyed to a string-flattened set of query terms and a page number
  });

  singleMatchFetching = observable.box(false);
  singleMatchNeedsNewFetch = observable.box(false);
  matchInView = observable.box(null);
  matchMap = observable.map({});
  teamRosterMap = observable.map({});

  matchTabInView = observable.box('overview');

  setMatchInView = action((matchId) => {
    this.matchInView.set(matchId);
  });

  addMatchData = action((matchId, matchData) => {
    this.matchMap[matchId] = matchData;
  })

  setMatchTabInView = action((tab) => {
    this.matchTabInView.set(tab);
  })

  roster1Fetching = observable.box(false);
  roster1Fetched = observable.box(false);
  roster2Fetching = observable.box(false);
  roster2Fetched = observable.box(false);

  addRosterData = action((teamId, rosterData, matchId) => {
    const RoleMap = {
      Top: 'Top',
      Jungle: 'Jungle',
      Jungler: 'Jungle',
      Mid: 'Mid',
      Bot: 'Bot',
      'AD Carry': 'Bot',
      Support: 'Support',
      Coach: 'Coach',
      Substitute: 'Substitute'
    }
    const RosterOrderMap = {
      Top: 1,
      Jungle: 2,
      Jungler: 2,
      Mid: 3,
      Bot: 4,
      'AD Carry': 4,
      Support: 5,
      Substitute: 6,
      Coach: 7
    }
    const newRosterData = []
    for (let player of rosterData) {
      player.position = RoleMap[player.role]
      player.rosterOrder = RosterOrderMap[player.role]
      newRosterData.push(player)
    }
    newRosterData.sort((a, b) => (a.rosterOrder > b.rosterOrder) ? 1 : -1);
    if (matchId) {
      if (!this.teamRosterMap.hasOwnProperty(teamId)) {
        this.teamRosterMap[teamId] = {};
      }
      this.teamRosterMap[teamId][matchId] = newRosterData;
    } else {
      this.teamRosterMap[teamId] = newRosterData;
    }
  });

  matchTeam1GamesAreFetching = observable.box(false);
  matchTeam1GamesAreFetched = observable.box(false);
  matchTeam2GamesAreFetching = observable.box(false);
  matchTeam2GamesAreFetched = observable.box(false);

  upcomingMatchesAreFetching = observable.box(false);
  upcomingMatchesAreFetched = observable.box(false);
  upcomingMatchesForAllAreFetched = observable.box(false);
  upcomingMatchesForAllNeedNewFetch = observable.box(false);
  upcomingMatchesforAll = observable([]);
  upcomingMatches = observable([]);
  upcomingMatchesEntity = observable.box('home');

  fetchUpcomingMatches = action(async () => {
    this.upcomingMatchesAreFetching.set(true);
    const matchesResponse = await API.getUpcomingMatches();
    this.upcomingMatchesForAll = matchesResponse;
    this.setUpcomingMatches(matchesResponse);
    this.upcomingMatchesAreFetched.set(true);
    this.upcomingMatchesForAllAreFetched.set(true);
    this.upcomingMatchesAreFetching.set(false);
  })

  fetchUpcomingMatchesForTeam = action(async (teamId) => {
    this.upcomingMatchesAreFetching.set(true);
    const matchesResponse = await API.getUpcomingMatchesForTeam(teamId);
    this.addTeamUpcomingMatches(teamId, matchesResponse)
    if (matchesResponse.length > 0) {
      this.setUpcomingMatches(matchesResponse);
    } else {
      this.setUpcomingMatches([]);
    }
    this.upcomingMatchesEntity.set(teamId);
    this.upcomingMatchesAreFetched.set(true);
    this.upcomingMatchesAreFetching.set(false);
  })

  fetchUpcomingMatchesForPlayer = action(async (playerId, teamId) => {
    this.upcomingMatchesAreFetching.set(true);
    const matchesResponse = await API.getUpcomingMatchesForTeam(teamId);
    this.addPlayerUpcomingMatches(playerId, matchesResponse)
    if (matchesResponse.length > 0) {
      this.setUpcomingMatches(matchesResponse);
    } else {
      this.setUpcomingMatches([]);
    }
    this.upcomingMatchesEntity.set(playerId);
    this.upcomingMatchesAreFetched.set(true);
    this.upcomingMatchesAreFetching.set(false);
  })

  setUpcomingMatches = action(async (matches) => {
    if (matches.length > 0) {
      this.upcomingMatches.replace(matches);
    } else {
      this.upcomingMatches.replace([]);
    }
  })

  matchesInProgressAreFetched = observable.box(false);
  matchesInProgressAreFetching = observable.box(false);
  matchesInProgressNeedNewFetch = observable.box(false);
  matchesInProgress = observable([]);

  fetchMatchesInProgress = action(async () => {
    this.matchesInProgressAreFetching.set(true);
    const matchesResponse = await API.getLiveMatches();
    this.setMatchesInProgress(matchesResponse);
    if (!this.matchesInProgressAreFetched.get()) {
      this.matchesInProgressAreFetched.set(true);
    }
    this.matchesInProgressAreFetching.set(false);

    return matchesResponse.length;
  })

  setMatchesInProgress = action(async (matches) => {
    this.matchesInProgress.replace(matches);
  })

  singleGameFetching = observable.box(false);
  singleGameNeedsNewFetch = observable.box(false);
  gameInView = observable.box(null);
  gameMap = observable.map({});

  gameTabInView = observable.box('overview');

  setGameInView = action((gameId) => {
    this.gameInView.set(gameId);
  });

  addGameData = action((gameId, gameData) => {
    this.gameMap[gameId] = gameData;
  })

  setGameTabInView = action((tab) => {
    this.gameTabInView.set(tab);
  })

  gameDrafts = observable.map({});

  getSingleGameDraft = action(async (gameId) => {
    const response = await API.getSingleGameDraft(gameId);
    if (response.status === 200) {
      const draftDto = response.body;
      this.setGameDraft(gameId, draftDto);
      return true;
    } else {
      this.setGameDraft(gameId, []);
      return false;
    }
  });

  setGameDraft = action(async (gameId, draft) => {
    this.gameDrafts[gameId] = draft;
  });

  recentResultsAreFetched = observable.box(false);
  recentResultsAreFetching = observable.box(false);
  recentResultsNeedNewFetch = observable.box(false);
  recentResults = observable([]);

  fetchRecentResults = action(async () => {
    this.recentResultsAreFetching.set(true);
    const matchesResponse = await API.getRecentResults();
    this.setRecentResults(matchesResponse);
    this.recentResultsAreFetched.set(true);
    this.recentResultsAreFetching.set(false);
  })

  setRecentResults = action(async (matches) => {
    this.recentResults.replace(matches);
  })

  recentVods = observable([]);
  recentVodsAreFetched = observable.box(false);
  recentVodsAreFetching = observable.box(false);
  getRecentVods = observable([]);

  fetchRecentVods = action(async () => {
    this.recentVodsAreFetching.set(true);
    const vodsResponse = await API.getRecentVods();
    this.setRecentVods(vodsResponse);
    this.recentVodsAreFetched.set(true);
    this.recentVodsAreFetching.set(false);
  })

  setRecentVods = action(async (vods) => {
    this.recentVods.replace(vods);
  })

  // Champions Queue
  cqSplitInView = observable.box();
  cqFiltersChanged = observable.box(false);

  cqLeaderboardIsFetching = observable.box(false);
  cqLeaderboard = observable({});
  cqLeaderboardKeyedByPlayer = observable({});

  cqSplitsFetching = observable.box(false);
  cqSplits = observable([]);
  cqSplitSelected = observable.box();
  cqFiltersSplitSelected = observable([]);
  cqCurrentSplit = observable.box();

  fetchCQSplits = action(async () => {
    this.cqSplitsFetching.set(true);
    const cqSplitsResponse = await API.fetchCQSplits();

    this.cqSplits.replace(cqSplitsResponse);
    this.cqCurrentSplit.set(cqSplitsResponse[0].id)

    this.cqSplitsFetching.set(false);
  })

  fetchCQLeaderboard = action(async (split) => {
    this.cqLeaderboardIsFetching.set(true);
    const leaderboardResponse = await API.fetchCQLeaderboard(split);
    const cleanedLeaderboard = [];
    var rank = 0;
    for (let player of leaderboardResponse) {
      const cleanedPlayer = {};
      rank++;
      cleanedPlayer.id = player.id;
      cleanedPlayer.Rank = rank;
      cleanedPlayer.Player = player.Player;
      cleanedPlayer.Team = player.Team;
      cleanedPlayer.LP = player.LP;
      cleanedPlayer.GP = player.GP;
      cleanedPlayer['W%'] = player['W%'];
      cleanedPlayer['OE Rating'] = player.ntile;
      cleanedLeaderboard.push(cleanedPlayer);
    }
    this.setCQLeaderboard(split, cleanedLeaderboard);

    this.cqLeaderboardKeyedByPlayer[split] = {};
    for (let player of cleanedLeaderboard) {
      this.cqLeaderboardKeyedByPlayer[split][player.id] = player;
    }

    this.cqFiltersChanged.set(false);
    this.cqLeaderboardIsFetching.set(false);
  })

  setCQLeaderboard = action(async (split, data) => {
    this.cqLeaderboard[split] = data;
  })

  playerInCqFetching = observable.box(false);
  isPlayerInCqMap = observable({});
  cqGamesAreFetching = observable.box(false);
  cqSplitOverviewIsFetching = observable.box(false);

  setPlayerInCq = action((playerId, answer) => {
    this.isPlayerInCqMap[playerId] = answer;
  })

  fetchPlayerCqGameDetails = action(async (playerId) => {
    this.cqGamesAreFetching.set(true);
    var response = null;

    response = await API.getPlayerCqGameDetails(playerId);
    if (response.status === 200 && response.body.length > 0) {
      const gamesDto = response.body;
      this.setPlayerCqGameDetails(playerId, gamesDto);
      this.gamesInView.replace(gamesDto);
    } else {
      console.log(
        `Could not fetch any player Champions Queue game details`
      );
      this.setPlayerCqGameDetails(playerId, []);
      this.gamesInView.replace([]);
    }

    this.cqGamesAreFetching.set(false);
  });

  setPlayerCqGameDetails = action(async (playerId, games) => {
    this.playerProfileDataMap[playerId].cqGameDetails = games;
  });
}

export default new store();
